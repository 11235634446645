<template>
  <!-- 保洁员信息提示框 -->
  <div class="container">
    <div class="row" v-if="trackInfo.address">
      <span>定位地点：</span>
      <span>{{ trackInfo.address }}</span>
    </div>
    <div class="row" v-if="objectInfo && objectInfo.vehicleId">
      <span>车辆自编号：</span>
      <span>{{ objectInfo ? objectInfo.vehicleId : '' }}</span>
    </div>
    <div class="row">
      <span>点位时间：</span>
      <span>{{ trackInfo.time }}</span>
    </div>
    <div class="row">
      <span>速度：</span>
      <span>{{ trackInfo.speed ? formatSpeed(Number(trackInfo.speed)).toFixed(2) + 'km/h' : '暂无速度' }}</span>
    </div>
    <div class="row">
      <span>方向：</span>
      <span>{{ trackInfo.rotation ? Number(trackInfo.rotation).toFixed(2) + '°' : '无' }}</span>
    </div>
    <div class="row">
      <span>作业状态：</span>
      <span>{{ workStatus ? workStatus : '无' }}</span>
    </div>
    <div class="row" v-if="controlMode">
      <span>驾驶模式：</span>
      <span>{{ controlMode }}</span>
    </div>
    <div class="row" v-if="trackInfo.soc">
      <span>电量：</span>
      <span>{{ trackInfo.soc ? Number(trackInfo.soc).toFixed(2):'' }}%</span>
    </div>
    <div class="row" v-if="trackInfo.satelliteNum">
      <span>卫星数量：</span>
      <span>{{ trackInfo.satelliteNum }}</span>
    </div>
  </div>
</template>
<script>
import { formatSpeed } from '@/utils/formatHelper'

export default {
  name: 'MapVehicleTrackInfoWindow',
  props: {
    // 对象信息
    objectInfo: {
      type: Object,
      default: () => {}
    },
    // 轨迹信息
    trackInfo: {
      type: Object,
      default: () => {}
    },
    currentMapAddress: {
      type: Object,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    }
  },
  computed: {
    workStatus() {
      if (!this.taskStatusOptions) {
        return ''
      }
      return this.selectDictLabel(this.taskStatusOptions, this.trackInfo.workStatus)
    },
    controlMode() {
      const mode = this.trackInfo.controlMode
      if (mode === 0) {
        return '人工模式'
      } else if (mode === 1) {
        return '智驾模式'
      } else if (mode === 2) {
        return '遥控模式'
      } else if (mode === -1) {
        return '未知模式'
      } else {
        return ''
      }
    }
  },
  mounted() {},
  methods: {
    formatSpeed
  },
  watch: {
    objectInfo(newVal, oldVal) {
      console.log('track-objectInfo', newVal)
    },
    trackInfo(newVal, oldVal) {}
  },
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 13px;
  .row {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
}
</style>
