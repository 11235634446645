var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.trackInfo.address
      ? _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("定位地点：")]),
          _c("span", [_vm._v(_vm._s(_vm.trackInfo.address))]),
        ])
      : _vm._e(),
    _vm.objectInfo && _vm.objectInfo.vehicleId
      ? _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("车辆自编号：")]),
          _c("span", [
            _vm._v(_vm._s(_vm.objectInfo ? _vm.objectInfo.vehicleId : "")),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c("span", [_vm._v("点位时间：")]),
      _c("span", [_vm._v(_vm._s(_vm.trackInfo.time))]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("span", [_vm._v("速度：")]),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.trackInfo.speed
              ? _vm.formatSpeed(Number(_vm.trackInfo.speed)).toFixed(2) + "km/h"
              : "暂无速度"
          )
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("span", [_vm._v("方向：")]),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.trackInfo.rotation
              ? Number(_vm.trackInfo.rotation).toFixed(2) + "°"
              : "无"
          )
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("span", [_vm._v("作业状态：")]),
      _c("span", [_vm._v(_vm._s(_vm.workStatus ? _vm.workStatus : "无"))]),
    ]),
    _vm.controlMode
      ? _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("驾驶模式：")]),
          _c("span", [_vm._v(_vm._s(_vm.controlMode))]),
        ])
      : _vm._e(),
    _vm.trackInfo.soc
      ? _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("电量：")]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.trackInfo.soc ? Number(_vm.trackInfo.soc).toFixed(2) : ""
              ) + "%"
            ),
          ]),
        ])
      : _vm._e(),
    _vm.trackInfo.satelliteNum
      ? _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("卫星数量：")]),
          _c("span", [_vm._v(_vm._s(_vm.trackInfo.satelliteNum))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }